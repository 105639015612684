import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = [ "from", "to", "submit" ]

  handleSubmit() {
    if ( this.fromTarget.value && this.toTarget.value ) {
      this.submitTarget.disabled = new Date(this.fromTarget.value) >= new Date(this.toTarget.value)
    }
  }
}