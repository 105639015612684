import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ExampleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  beforeAddVariant() {
    for (name in CKEDITOR.instances) { CKEDITOR.instances[name].destroy(true) }
  }

  addVariantSuccess() {
    initSelect2()
    $('.maskMoneyMe').maskMoney()
    document.querySelectorAll(".ckeditor-me").forEach(function(elem) { CKEDITOR.replace(elem) })
  }

  removeVariant(event) {
    const element = event.target
    element.previousElementSibling.value = 1
    element.closest(".fields").style.display = "none"
  }

  changeSelectionSuccess(element, reflex) {
    let variantId = element.value
    handleProductSlider(variantId)
    $("#productTab li:first-child a").tab("show")
  }

  validateVariant(event) {
    let variantSelect = document.getElementById('product_variant_assoc')
    if (variantSelect.value == '') {
      event.preventDefault()
      variantSelect.style.borderColor = 'red'
    }
  }

  selectVariant() {
    const variantId = window.location.search.split('?pva=')[1]
    const variantSelect = document.getElementById('product_variant_assoc')
    variantSelect.value = variantId
    handleProductSlider(variantId)
  }

  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="ExampleReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#example"
   *
   *   error - error message from the server
   */

  // beforeUpdate(element, reflex) {
  //  element.innerText = 'Updating...'
  // }

  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }
}

function handleProductSlider(variantId) {
  let activeIndex = 0
  let productSlider = document.getElementById('product-slider')
  productSlider.querySelectorAll('img').forEach(function(elem, index) {
    if ( elem.dataset.vid == variantId ) { activeIndex = index } 
  })
  productSlider.swiper.slideTo(activeIndex)
}